var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"body"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"content"},[_c('div',{staticClass:"big"},[_vm._m(4),_c('div',{staticClass:"button",on:{"click":_vm.refreshPage}},[_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"first-content__bg",attrs:{"src":"https://image.kmeta.world/website/img/bigbac3.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo2"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/img/logo2.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text1"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/img/text1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text3"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/img/text3.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"box1_inner"},[_c('div',{staticClass:"qr"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/img/ios1logo.png","alt":""}})]),_c('div',{staticClass:"txt"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/img/ioslogo.png","alt":""}})]),_c('div',{staticClass:"download"},[_c('p',{staticClass:"p1"},[_vm._v("Download on the")]),_c('p',{staticClass:"p2"},[_vm._v("App Store")])])])])]),_c('div',{staticClass:"box1"},[_c('div',{staticClass:"box1_inner"},[_c('div',{staticClass:"qr"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/img/ad1logo.png","alt":""}})]),_c('div',{staticClass:"txt"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/img/adlogo.png","alt":""}})]),_c('div',{staticClass:"download"},[_c('p',{staticClass:"p1"},[_vm._v("Download on the")]),_c('p',{staticClass:"p2"},[_vm._v("Android")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"18px","height":"19px"}},[_c('a',[_c('img',{staticStyle:{"width":"18px","height":"19px"},attrs:{"src":"https://image.kmeta.world/website/star1.png"}})])])
}]

export { render, staticRenderFns }