<template>
  <div>
    <!-- 第一屏 -->
    <div class="body">
      <div>
        <img
          class="first-content__bg"
          src="https://image.kmeta.world/website/img/bigbac3.png"
        />
      </div>
      <div class="logo2">
        <img src="https://image.kmeta.world/website/img/logo2.png" />
      </div>
      <div class="text1">
        <img src="https://image.kmeta.world/website/img/text1.png" />
      </div>
      <div class="text3">
        <img src="https://image.kmeta.world/website/img/text3.png" />
      </div>
      <div class="content">
        <!-- 中间的logo -->
        <!-- <div class="logo">
          <img src="http://image.kmeta.world/website/logo.png" />
        </div> -->
        <!-- 下方的盒子 -->
        <div class="big">
          <div class="left">
            <div class="box1">
              <div class="box1_inner">
                <div class="qr">
                  <img
                    src="https://image.kmeta.world/website/img/ios1logo.png"
                    alt=""
                  />
                </div>
                <div class="txt">
                  <div class="icon">
                    <img
                      src="https://image.kmeta.world/website/img/ioslogo.png"
                      alt=""
                    />
                  </div>
                  <div class="download">
                    <p class="p1">Download on the</p>
                    <p class="p2">App Store</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box1">
              <div class="box1_inner">
                <div class="qr">
                  <img
                    src="https://image.kmeta.world/website/img/ad1logo.png"
                    alt=""
                  />
                </div>
                <div class="txt">
                  <div class="icon">
                    <img
                      src="https://image.kmeta.world/website/img/adlogo.png"
                      alt=""
                    />
                  </div>
                  <div class="download">
                    <p class="p1">Download on the</p>
                    <p class="p2">Android</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button" @click="refreshPage">
            <div style="width: 18px; height: 19px">
              <a>
                <img
                  src="https://image.kmeta.world/website/star1.png"
                  style="width: 18px; height: 19px"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 第二屏 -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { reqDataCollect } from "../api/dataCollect";

export default {
  data() {
    return {
      isdownload: false,
      islogin: false,
      iscon: false,
      p: "",
      // iscopyright:false
    };
  },
  mounted() {
    console.log(222);
    // 获取 URL 中的查询字符串部分
    const queryString = window.location.search;

    // 解析查询字符串，获取参数对象
    const params = new URLSearchParams(queryString);

    // 获取参数 p 的值
    this.p = params.get("p");
    // this.p ='eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNzY3Nzk4NTUwMDczODQzNzE0IiwiaWF0IjoxNzEwMzIzNDAyLCJleHAiOjE3MTM5MjM0MDJ9.nyTaq7DX3SVO0WkDK5HjDABfHgeE9KPxiazuzCAdqrhV_-RIHrHrip4jz-2tsWWkl-EtADbodkDGC36qCENeqA'
    // 现在你可以在这里使用参数 p 了
    console.log("参数p的值为：", this.p);
    if (this.p && this.p !== "" && this.p !== null && this.p !== undefined) {
      this.dataCollect();
    }
  },
  methods: {
    async dataCollect() {
      // console.log(111);
      await reqDataCollect(this.p);
    },
    Scrollbottom() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        // alert("滚动到底部了")
        // this.iscopyright = true
        // this.pageNo++
        // console.log(this.pageNo);
        // this.fetchData()
      } else {
        // this.iscopyright = false
      }
    },
    refreshPage() {
      window.location.href = "http://kmeta.world";
    },
    show() {
      var download = document.getElementById("download");
      download.style.border = "rgba(35, 174, 216, 0.5) 2px solid";
      this.isdownload = true;
    },
    noshow() {
      this.isdownload = false;
      var download = document.getElementById("download");

      download.style.border = "rgba(35, 174, 216, 1) 2px solid";
    },
    showcon() {
      this.iscon = true;
      var contact = document.getElementById("contact");
      contact.style.border = "rgba(214, 72, 239, 0.5)2px solid";
      contact.style.background = "rgba(214, 72, 239, 0.15)";
    },
    noshowcon() {
      this.iscon = false;
      var contact = document.getElementById("contact");

      contact.style.border = "rgba(214, 72, 239, 1)2px solid";
    },
    showcon1() {
      var contact = document.getElementById("contact");

      contact.style.background = "rgba(214, 72, 239, 1)";
    },

    noshowcon1() {
      var contact = document.getElementById("contact");

      contact.style.background = "rgba(214, 72, 239, 0.15)";
    },
  },
  created() {
    // window.addEventListener("scroll", this.Scrollbottom); //页面加载时监听滚动事件
    // let domWidth = document.body.clientWidth || document.body.scrollWidth;
    // let domHeight = document.body.clientHeight || document.body.scrollHeight;
    // // 点击除了joinus之外的地方联系我们关闭
    // let that = this; //将vue对象的引用保存在that中
    // document.addEventListener("click", function (event) {
    //   // console.log("e", event);
    //   var contact = document.getElementById("contact");
    //   if (event.target !== contact && !contact.contains(event.target)) {
    //     that.noshowcon();
    //   }
    // });
  },

  destroyed() {
    // window.removeEventListener("scroll", this.Scrollbottom); //页面离开后销毁监听事件
    // window.removeEventListener("click"); //页面离开后销毁监听事件
  },
};
</script>

<style lang="scss" scoped>
/*通配符号让所有标签的margin和padding为0*/
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
.body {
  width: 100%;
  font-family: HarmonyOS_Sans_SC;
  min-width: 1000px;

  min-height: 100vh;
  position: relative;
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // height: 100vh;
  /* width: 1890px;
  height: 950px; */
  /*100vh是指的可视区域的总高度*/
  // background-image: url(http://image.kmeta.world/website/big.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* -moz-background-size:100% 100%;  */
  /* background-size:100% 100%; */
}
.body2 {
  width: 100%;

  min-width: 1000px;
  font-family: HarmonyOS_Sans_SC;
  min-height: 100vh;
  position: relative;
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // height: 100vh;
  /* width: 1890px;
  height: 950px; */
  /*100vh是指的可视区域的总高度*/
  // background-image: url(./img/bigbac2.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* -moz-background-size:100% 100%;  */
  /* background-size:100% 100%; */
}
.first-content__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  // z-index: 1;
}
.logo2 {
  width: 247px;
  height: 78px;
  object-fit: cover;
  // background: red;
  position: absolute;

  top: 109px;

  left: 50%;
  transform: translate(-50%, 0);
  z-index: 900;
}
.logo2 img {
  width: 247px;
  height: 87px;
  object-fit: cover;
}
.text1 {
  width: 695;
  height: 109px;
  object-fit: cover;
  // background: red;
  position: absolute;

  top: 239px;

  left: 50%;
  transform: translate(-50%, 0);
  z-index: 900;
}
.text1 img {
  width: 695;
  height: 109px;
  object-fit: cover;
}
.text2 {
  width: 546px;
  height: 45px;
  object-fit: cover;
  // background: red;
  position: absolute;

  top: 439px;

  left: 50%;
  transform: translate(-50%, 0);
  z-index: 900;
}
.text2 img {
  width: 546px;
  height: 45px;
  object-fit: cover;
}
.text3 {
  width: 367px;
  height: 68px;
  object-fit: cover;
  // background: red;
  position: absolute;

  top: 355px;

  left: 50%;
  transform: translate(-50%, 0);
  z-index: 900;
}
.text3 img {
  width: 367px;
  height: 68px;
  object-fit: cover;
}
.head {
  width: 100%;
  height: 39px;
  line-height: 39px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  font-size: 14px;
}

.content {
  // flex: 1;
  // width: 100%;
  /* position: relative; */
  // width: 100%;
  height: 250px;
  // position: absolute;
  // bottom: 0px;
  // top: 830px;
  // border: #1c883c 1px solid;
  // display: flex;
  // justify-content: center;
  // background: #23aed8;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  z-index: 900;
}
// .logo {
//   width: 207px;
//   height: 249px;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   margin-left: -104px;
//   margin-top: -125px;
// }
// .logo img {
//   width: 207px;
//   height: 249px;
// }

// .bottom {
//   height: 44px;
//   width: 100%;
//   background-color: #bbf;
//   position: absolute;
//   top: 90%;
// }
.big {
  height: 250px;
  // background: #3050ff;
  // width: 1802px;
  // border: #23aed8 1px solid;
  // position: absolute;
  // left: 60px;
  // right: 60px;

  // bottom: 48px;
}
.big .left {
  position: absolute;
  bottom: 48px;
  left: 60px;
  width: 290px;
  height: 160px;
  // background-color: #984040;
  display: flex;
  justify-content: space-between;
}

.big .left .box1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 160px;
  // background-color: #984040;

  background-color: rgba(35, 174, 216, 0.3);
  border-radius: 20px;
}
.box1_inner {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-content: center;
  width: 100px;
  height: 137px;
  // background-color: #499840;
}
.big .left .box1 .qr {
  width: 100px;
  height: 100px;
}
.big .left .box1 .qr img {
  width: 100px;
  height: 100px;
}
.big .left .box1 .txt {
  display: flex;
  // justify-content: space-between;
  // background-color: #3050ff;
  width: 110px;
  height: 26px;
  margin-top: 11px;
  // background: #cf4444;
}
.big .left .box1 .icon {
  width: 22px;
  height: 24px;
}
.big .left .box1 .icon img {
  width: 22px;
  height: 24px;
}
.big .left .box1 .download {
  // background: #cf4444;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 24px;
  margin-left: 6px;
}
.big .left .box1 .download .p1 {
  font-family: HarmonyOS_Sans_SC_Black;
  font-weight: bold;
  font-size: 9px;
  color: rgba(255, 255, 255, 1);
}
.big .left .box1 .download .p2 {
  font-family: HarmonyOS_Sans_SC_Light;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 36px;
  /* background-color: #bfa; */
  // position: absolute;
  // bottom: 0px;
  // left: 50%;
  // margin-left: -42px;
  border: rgba(50, 26, 193, 0.5) 2px solid;
  border-radius: 16px;

  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translate(-50%, 0);
}
.button:hover {
  border: rgba(50, 26, 193, 1) 2px solid;
}
</style>
